import React, { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import CardPanel from "components/Core/Card/CardPanel"
import GridContainer from "components/material-dashboard-pro-react/components/Grid/GridContainer.js"
import GridItem from "components/material-dashboard-pro-react/components/Grid/GridItem.js"
import sectionStyle from "LandingPageMaterial/Views/Sections/sectionStyle"
import LandingPage from "LandingPageMaterial/Layout/LandingPage"
import MobileStoreButton from "react-mobile-store-button"
import { isIOS, isAndroid } from "react-device-detect"
import LogoGrupo911 from "../../static/grupo-nueve-once.png"

const NewDivContainerPay = styled("div")(() => {
  const newStyles = sectionStyle
  return {
    ...newStyles.containerPayment,
  }
})
const NewDivContainer = styled("div")(() => {
  const newStyles = sectionStyle
  return {
    ...newStyles.container,
  }
})
const NewDivFeaturesPayment = styled("div")(() => {
  const newStyles = sectionStyle
  return {
    ...newStyles.featuresPayment,
  }
})

export default function Pagos() {
  const [localIsIOS, SetLocalIsIOS] = useState(false)
  const [localIsAndroid, SetLocalIsAndroid] = useState(false)
  const [isPWA, SetIsPWA] = useState(false)
  const iOSUrl =
    "https://apps.apple.com/us/app/grupo-nueve-once-connect/id1515545949"
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.gno.farmacia&hl=es_VE&gl=US"

  useEffect(() => {
    if (isIOS) SetLocalIsIOS(true)
    else if (isAndroid) SetLocalIsAndroid(true)
    else SetIsPWA(true)
  }, [])

  return (
    <LandingPage color="white" pageTitle="TeleMedicina">
      <NewDivContainerPay>
        <div className="cd-section">
          <NewDivContainer>
            <NewDivFeaturesPayment>
              <GridContainer justifyContent="center" alignItems="center">
                <GridItem md={10} xs={12}>
                  <CardPanel
                    titulo="Telemedicina"
                    icon="health_and_safety"
                    iconColor="primary"
                  >
                    <GridContainer justifyContent="center" spacing={0}>
                      <GridItem xs={12} sm={9} md={12}>
                        <br />
                        <p align="center">
                          Estimado Asegurado, gracias a nuestra alianza con Grupo Nueve 
                          Once te ofrecemos la posibilidad de realizar una Consulta Médica 
                          a distancia, a través de una Videollamada a nuestros grupo de 
                          profesionales de la salud, completamente amparado por tu póliza.
                          <br />
                          <small>
                            *(Luego de registrarte en el aplicativo)
                          </small>
                        </p>

                        <br />
                      </GridItem>
                      <GridItem xs={7} sm={5} md={3} lg={3}>
                        {localIsIOS && (
                          <MobileStoreButton
                            store="ios"
                            width={170}
                            url={iOSUrl}
                            linkProps={{ title: "iOS Store Button" }}
                          />
                        )}
                        {localIsAndroid && (
                          <MobileStoreButton
                            store="android"
                            width={180}
                            url={androidUrl}
                            linkProps={{ title: "Android Store Button" }}
                          />
                        )}
                        {isPWA && (
                          <a
                            href="https://pwa.gruponueveonce.com/"
                            target="_blank" rel="noreferrer"
                          >
                            <img
                              src={LogoGrupo911}
                              width={200}
                              alt="Grupo Nueve Once"
                            />
                          </a>
                        )}
                        <p>
                          <br />
                        </p>
                      </GridItem>
                    </GridContainer>
                  </CardPanel>
                </GridItem>
              </GridContainer>
            </NewDivFeaturesPayment>
          </NewDivContainer>
        </div>
      </NewDivContainerPay>
    </LandingPage>
  )
}
